import { default as _91_46_46_46url_93Ex1tywYK6ZMeta } from "/app/pages/partnersNew/[...city]/[...url].vue?macro=true";
import { default as internet_45mobilebvy6hBvQDWMeta } from "/app/pages/partnersNew/[...city]/internet-mobile.vue?macro=true";
import { default as internet_45tv_45mobileERi7wBNgGbMeta } from "/app/pages/partnersNew/[...city]/internet-tv-mobile.vue?macro=true";
import { default as internet_45tvYHOof1uTG3Meta } from "/app/pages/partnersNew/[...city]/internet-tv.vue?macro=true";
import { default as internetV7QMzrW9EzMeta } from "/app/pages/partnersNew/[...city]/internet.vue?macro=true";
import { default as indexN7PJPXl8KLMeta } from "/app/pages/partnersNew/[...city]/not-available/index.vue?macro=true";
import { default as quiz_45completeXESbK9ER1WMeta } from "/app/pages/partnersNew/[...city]/quiz-complete.vue?macro=true";
import { default as indexUPT0GM1Gs8Meta } from "/app/pages/partnersNew/[...city]/quiz/index.vue?macro=true";
import { default as _91id_93yPWu4n6L38Meta } from "/app/pages/partnersNew/[...city]/tariffs/[id].vue?macro=true";
import { default as index5TXyUUVrlaMeta } from "/app/pages/partnersNew/checkout/index.vue?macro=true";
import { default as indexangwh18MoLMeta } from "/app/pages/partnersNew/complete/index.vue?macro=true";
import { default as index4LX5ytoOcsMeta } from "/app/pages/partnersNew/contacts/index.vue?macro=true";
import { default as indexbqCoLUZVHLMeta } from "/app/pages/partnersNew/health/index.vue?macro=true";
import { default as indexwLLOODPTywMeta } from "/app/pages/partnersNew/index.vue?macro=true";
import { default as index5JVahj6E1RMeta } from "/app/pages/partnersNew/legal/[...query]/index.vue?macro=true";
import { default as indexJxR4rqmNNSMeta } from "/app/pages/partnersNew/legal/index.vue?macro=true";
import { default as indexw4wNisHsSYMeta } from "/app/pages/partnersNew/locations/index.vue?macro=true";
import { default as thanks_45for_45choiceapG3ghr6BaMeta } from "/app/pages/partnersNew/thanks-for-choice.vue?macro=true";
export default [
  {
    name: "city-url",
    path: "/:city(.*)*/:url(.*)*",
    component: () => import("/app/pages/partnersNew/[...city]/[...url].vue")
  },
  {
    name: "city-internet-mobile",
    path: "/:city(.*)*/internet-mobile",
    component: () => import("/app/pages/partnersNew/[...city]/internet-mobile.vue")
  },
  {
    name: "city-internet-tv-mobile",
    path: "/:city(.*)*/internet-tv-mobile",
    component: () => import("/app/pages/partnersNew/[...city]/internet-tv-mobile.vue")
  },
  {
    name: "city-internet-tv",
    path: "/:city(.*)*/internet-tv",
    component: () => import("/app/pages/partnersNew/[...city]/internet-tv.vue")
  },
  {
    name: "city-internet",
    path: "/:city(.*)*/internet",
    component: () => import("/app/pages/partnersNew/[...city]/internet.vue")
  },
  {
    name: "city-not-available",
    path: "/:city(.*)*/not-available",
    component: () => import("/app/pages/partnersNew/[...city]/not-available/index.vue")
  },
  {
    name: "city-quiz-complete",
    path: "/:city(.*)*/quiz-complete",
    meta: quiz_45completeXESbK9ER1WMeta || {},
    component: () => import("/app/pages/partnersNew/[...city]/quiz-complete.vue")
  },
  {
    name: "city-quiz",
    path: "/:city(.*)*/quiz",
    meta: indexUPT0GM1Gs8Meta || {},
    component: () => import("/app/pages/partnersNew/[...city]/quiz/index.vue")
  },
  {
    name: "city-tariffs-id",
    path: "/:city(.*)*/tariffs/:id()",
    component: () => import("/app/pages/partnersNew/[...city]/tariffs/[id].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: index5TXyUUVrlaMeta || {},
    component: () => import("/app/pages/partnersNew/checkout/index.vue")
  },
  {
    name: "complete",
    path: "/complete",
    meta: indexangwh18MoLMeta || {},
    component: () => import("/app/pages/partnersNew/complete/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/partnersNew/contacts/index.vue")
  },
  {
    name: "health",
    path: "/health",
    meta: indexbqCoLUZVHLMeta || {},
    component: () => import("/app/pages/partnersNew/health/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/partnersNew/index.vue")
  },
  {
    name: "legal-query",
    path: "/legal/:query(.*)*",
    component: () => import("/app/pages/partnersNew/legal/[...query]/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/partnersNew/legal/index.vue")
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/app/pages/partnersNew/locations/index.vue")
  },
  {
    name: "thanks-for-choice",
    path: "/thanks-for-choice",
    component: () => import("/app/pages/partnersNew/thanks-for-choice.vue")
  }
]