import {
  AuthApi,
  BlogApi,
  CallsApi,
  Configuration,
  ContentApi,
  CoreApi,
  InteractionsApi,
  LocationsApi,
  ProvidersApi,
  ReviewsApi,
  SiteOptionsApi,
  SuggestionsApi,
} from './openapi'
import { baseOptions, mainInstance } from './axios'

export * from './openapi'
export const basePath
  = import.meta.env.VITE_NUXT_ENV_BACKEND_API_ROOT_URL
  || 'https://dev-api.dominter.net'

const config = new Configuration({
  basePath,
  baseOptions,
})

// swagger
export const interactionsApi = new InteractionsApi(
  config,
  undefined,
  mainInstance,
)
export const suggestionsApi = new SuggestionsApi(
  config,
  undefined,
  mainInstance,
)
export const locationsApi = new LocationsApi(config, undefined, mainInstance)
export const contentApi = new ContentApi(config, undefined, mainInstance)
export const reviewsApi = new ReviewsApi(config, undefined, mainInstance)
export const providersApi = new ProvidersApi(config, undefined, mainInstance)
export const blogApi = new BlogApi(config, undefined, mainInstance)
export const coreApi = new CoreApi(config, undefined, mainInstance)
export const callsApi = new CallsApi(config, undefined, mainInstance)
export const siteOptionsApi = new SiteOptionsApi(
  config,
  undefined,
  mainInstance,
)
export const authApi = new AuthApi(config, undefined, mainInstance)
