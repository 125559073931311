import ThumborUrlBuilder from 'thumbor-url-builder';

export default defineNuxtPlugin({
  name: 'thumbor',
  parallel: true,
  setup () {
    const thumborURL = new ThumborUrlBuilder(import.meta.env.VITE_THUMBOR_SECURITY_KEY, (import.meta.env.VITE_ABSOLUTE_THUMBOR_URL || import.meta.env.VITE_THUMBOR_URL))

    return {
      provide: {
        thumborURL
      }
    }
  }
})