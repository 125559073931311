import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'

export function getAddresLocation(returnOnly?: boolean) {
  const ctx = useNuxtApp()
  const client = ctx._apolloClients.default
  const mainStore = useMainStore()
  const tariffsStore = useTariffs()
  const cityStore = useCities()
  const currentCity = computed(() => cityStore.getCity)
  const getCommonHeaders = computed(() => mainStore.getCommonHeaders)

  const getPassThrough = async (input) => {
    const res = (
      await client.query({
        query: gql`
          query getPassProvider($input: LocationInfoInput!, $providersInput2: BaseLocationProvidersInput) {
            location {
              locationTerms(input: $input) {
                providers(input: $providersInput2) {
                  passThroughProviderId
                }
              }
            }
          }
        `,
        variables: {
          input: {
            addrObjFiasId: currentCity.value?.fias_id,
            ...input,
          },
          providersInput2: {},
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location

    tariffsStore.passThroughProviderId = res?.locationTerms?.providers?.passThroughProviderId
  }
  const getAddresLoc = async (input, woPassProvider) => {
    const res = (
      await client.query({
        query: gql`
          query getLocation($input: LocationInfoInput!) {
            location {
              reducedLocation(input: $input) {
                softNoindex
                fiasRegion
                hash
                autoGeneratedUrl
                addrObjFiasId
                houseFiasId
                streetFiasId
                fiasPath
                houseId
                addressName
                houseNumber
                houseType
                lat
                lon
                houseUrl
              }
            }
          }
        `,
        variables: {
          input: {
            addrObjFiasId: currentCity.value?.fias_id,
            ...input,
          },
          locationTermsInput2: {
            addrObjFiasId: currentCity.value?.fias_id,
            ...input,
          },
          providersInput2: {},
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location

    if (!returnOnly && res?.reducedLocation?.hash) {
      const houseCheck = !!(res?.reducedLocation?.houseId || res?.reducedLocation?.houseFiasId || (res?.reducedLocation?.streetFiasId && res?.reducedLocation?.houseNumber))

      tariffsStore.house = res?.reducedLocation?.autoGeneratedUrl && houseCheck ? res.reducedLocation : {}
      cityStore.softNoindex = res.reducedLocation.softNoindex
    }

    if (!woPassProvider) {
      input.url = res.reducedLocation?.autoGeneratedUrl
      getPassThrough(input)
    }

    return res.reducedLocation
  }

  return { getAddresLoc }
}
